import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogService } from 'src/app/services/dialog.service';
import { AlertService } from 'src/app/services/alert.service';
import { IEmployee } from '../../models/interfaces/i-employee';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-action-button',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './action-button.component.html',
  styleUrl: './action-button.component.scss',
})
export class ActionButtonComponent implements ICellRendererAngularComp {
  params: any;
  teller!: IEmployee;
  showButton: boolean = true;

  constructor(
    private alertService: AlertService,
    private dialogService: DialogService,
    private azureAdService: AzureAdService,
    private generalDataService: GeneralDataService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.azureAdService.getCurrentTeller().subscribe((x) => {
      this.teller = x;
    });
  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.showButton = !this.params.data.resolutionDate;
  }

  onClick() {
    this.dialogService
      .confirmationDialog({
        title: 'Are you sure you want to reject?',
        message: 'An explanation is required.',
        isRejected: true,
      })
      .subscribe((result) => {
        if (result[0]) {
          this.loadingService.startLoading();
          this.generalDataService
            .rejectDispute(this.params.data.disputeGuid, {
              tellerNumber:
                this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID,
              resolutionReason: result[1],
              isReviewed: true,
            })
            .subscribe({
              next: () => {
                this.params.onClick(this.params);
              },
              error: (e) => {
                console.log(e), this.formatError(e, 'Reject');
              },
              complete: () => {
                this.loadingService.stopLoading();
              },
            });
        }
      });
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  formatError(e: any, msg: string): void {
    return typeof e.error === 'string'
      ? this.alertService.addError(`Unable to ${msg} - ` + e.error)
      : this.alertService.addError(`Unable to ${msg}`);
  }
}
