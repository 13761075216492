import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _nonFraudColumnHeaders = {
    isReviewed: '',
    disputeAmount: 'DISPUTED AMOUNT',
    disputeStatus: 'STATUS',
    reasonDescription: 'REASON',
    disputeType: 'DISPUTE TYPE',
    memberName: 'MEMBER',
    accountDescription: 'SOURCE TYPE',
    appleSubmitDate: 'DISPUTED ON',
    memberNumber: 'MEMBER NUMBER',
  };

  private _fraudColumnHeaders = {
    isReviewed: '',
    disputeAmount: 'DISPUTED AMOUNT',
    disputeStatus: 'STATUS',
    reasonDescription: 'REASON',
    transactionDescription: 'DESCRIPTION',
    disputeType: 'DISPUTE TYPE',
    memberName: 'MEMBER',
    accountDescription: 'SOURCE TYPE',
    appleSubmitDate: 'DISPUTED ON',
    memberNumber: 'MEMBER NUMBER',
  };

  private _allDisputesColumnHeaders = {
    disputeAmount: 'DISPUTED AMOUNT',
    disputeStatus: 'STATUS',
    reasonDescription: 'REASON',
    transactionDescription: 'DESCRIPTION',
    disputeType: 'DISPUTE TYPE',
    memberName: 'MEMBER',
    accountDescription: 'SOURCE TYPE',
    appleSubmitDate: 'DISPUTED ON',
    memberNumber: 'MEMBER NUMBER',
  };

  private _allDisputesFilterArray: ReadonlyArray<any> =[
    {
      name: 'Status',
      columnProp: 'disputeStatus',
      options: [],
    },
    {
      name: 'Reason',
      columnProp: 'reasonDescription',
      options: [],
    },
    {
      name: 'Dispute Type',
      columnProp: 'disputeType',
      options: [],
    },
    {
      name: 'Source Type',
      columnProp: 'accountDescription',
      options: [],
    },
    {
      name: 'Member Number',
      columnProp: 'memberNumber',
      options: [],
    },
  ];

  private _defaultFilterArray: ReadonlyArray<any> =[
    { name: '', 
      columnProp: 'isReviewed', 
      options: [],
    },
    {
      name: 'Reason',
      columnProp: 'reasonDescription',
      options: [],
    },
    {
      name: 'Source Type',
      columnProp: 'accountDescription',
      options: [],
    },
    {
      name: 'Member Number',
      columnProp: 'memberNumber',
      options: [],
    },
  ];

  get nonFraudColumnHeaders() {
    return this._nonFraudColumnHeaders;
  }

  get fraudColumnHeaders() {
    return this._fraudColumnHeaders;
  }

  get allDisputesColumnHeaders() {
    return this._allDisputesColumnHeaders;
  }

  get allDisputesFilterArray() {
    return this._allDisputesFilterArray;
  }

  get defaultFilterArray() {
    return this._defaultFilterArray;
  }

  constructor() {}

  isFraud(type: number): boolean {
    return [475, 481, 10000, 10001, 10005].includes(type);
  }

  isZelleFraud(type: number): boolean {
    return [10000, 10001, 10005].includes(type);
  }
}
