import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { IConfigSettings } from '../shared/models/interfaces/i-config-settings';
import { IDispute } from '../shared/models/interfaces/i-dispute';
import { IReason } from '../shared/models/interfaces/i-reason';
import { SubmitData } from '../shared/models/submit-data';
import { ApiHelperService } from './api-helper.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralDataService {
  configSettings: IConfigSettings;
 
  constructor(
    private apiHelperService: ApiHelperService,
    private appConfigService: AppConfigService
  ) {
    this.configSettings = this.appConfigService.config;
  }

  getOpenStatusDashboardView(requiresResponseOnly: boolean): Observable<IDispute[]> {
    return this.apiHelperService.get<IDispute[]>(
      this.configSettings.baseUrl +`/DashboardView?requiresResponseOnly=${requiresResponseOnly}`,this.generateHeaders()).pipe(
      map(data => data.sort((a,b) => {
        return <any>new Date(b.modifiedDate) - <any>new Date(a.modifiedDate);
      }))
    );
  }

  rejectDispute(guid: string,data:SubmitData) {
    return this.apiHelperService.put<any>(this.configSettings.baseUrl + `/RejectDispute/${guid}`,data,this.generateHeaders());
  }

  payDispute(guid: string,data:SubmitData) {
    return this.apiHelperService.put<any>(this.configSettings.baseUrl + `/PayDispute/${guid}`,data,this.generateHeaders());
  }

  updatePscuDispute(guid: string, data:SubmitData) {
    return this.apiHelperService.patch(this.configSettings.baseUrl + `/PscuDispute/${guid}`, [{
      path: '/claim/reasonId',
      op: 'replace',
      value: data.reasonId
    },
    {
      path: '/disputeAmount',
      op: 'replace',
      value: data.disputeAmount
    },
    {
      path: '/isReviewed',
      op: 'replace',
      value: data.isReviewed
    },
    {
      path: '/lastModifiedTeller',
      op: 'replace',
      value: data.lastModifiedTeller
    }],this.generatePatchHeaders());
  }

  updateZelleDispute(guid: string, data:SubmitData) {
    return this.apiHelperService.patch(this.configSettings.baseUrl + `/ZelleDispute/${guid}`, [{
      path: '/claim/reasonId',
      op: 'replace',
      value: data.reasonId
    },
    {
      path: '/infoComment',
      op: 'replace',
      value: data.infoComment
    },
    {
      path: '/disputeAmount',
      op: 'replace',
      value: data.disputeAmount
    },
    {
      path: '/isReviewed',
      op: 'replace',
      value: data.isReviewed
    },
    {
      path: '/lastModifiedTeller',
      op: 'replace',
      value: data.lastModifiedTeller
    }],this.generatePatchHeaders());
  }

  updateDisputeReviewStatus(guid: string, type:number,data:SubmitData) {
    let redirect = type ===1 ? '/PscuDispute/' : '/ZelleDispute/';
    return this.apiHelperService.patch(this.configSettings.baseUrl + `${redirect}`+`${guid}`, [
    {
      path: '/isReviewed',
      op: 'replace',
      value: data.isReviewed
    },
    {
      path: '/lastModifiedTeller',
      op: 'replace',
      value: data.lastModifiedTeller
    }],this.generatePatchHeaders());
  } 

  getReasons(): Observable<IReason[]> {
    return of(localStorage.getItem('disputeReasons')).pipe(switchMap(reasons => {
      if (!reasons) {
        return this.apiHelperService.get<IReason[]>(this.configSettings.baseUrl+`/Reasons`,this.generateHeaders()).
        pipe(tap(ar => localStorage.setItem('disputeReasons',JSON.stringify(ar))));        
      }
      return of(JSON.parse(reasons));
    }))
  }

  reopenDispute(guid:string, data:SubmitData):Observable<any> {
    return this.apiHelperService.put<any>(this.configSettings.baseUrl+ `/ReopenDispute/${guid}`,data,this.generateHeaders());
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({'X-Functions-Key': `${this.configSettings.apimKey}`
      })
    }
  }

  private generatePatchHeaders = () => {
    return {
      headers: new HttpHeaders({'X-Functions-Key': `${this.configSettings.apimKey}`,'content-type':'application/json-patch+json'})
    }
  }
}
