import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AzureAdService } from './azure-ad.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private azureAdService: AzureAdService,
    private router: Router,
    private commonService: CommonService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ):
    | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.azureAdService.getAADdistgroup().subscribe((groups) => {
      const isViewOnly = this.commonService.getUserPrivilege(groups, 'usersSG');
      const isAdmin = this.commonService.getUserPrivilege(groups, 'adminDG');
      if (!isAdmin && !isViewOnly) 
        this.router.navigate(['/unauthorized']);
      return false;
    });

    return true;
  }

  canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot ):
    | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
