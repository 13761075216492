import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AzureAdService } from './azure-ad.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ViewGuard  {
  constructor(
    private azureAdService: AzureAdService,
    private router: Router,
    private commonService: CommonService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ):
    | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    this.azureAdService.getAADdistgroup().subscribe((groups) => {
      const isViewOnly = this.commonService.getUserPrivilege(groups, 'usersSG');
      if (isViewOnly && (url === '/dashboard/fraud' || url === '/dashboard/non-fraud' || url === '/dashboard/cards' ))
        this.router.navigate(['/dashboard/all']);
      return false;
    });

    return true;
  }

  canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot ):
    | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
