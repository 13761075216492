import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { ConfigService } from 'src/app/services/config.service';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { IDispute } from 'src/app/shared/models/interfaces/i-dispute';
import { DisputeDetailsDialogComponent } from '../../shared/components/dispute-details-dialog/dispute-details-dialog.component';
import { DisputeService } from '../dispute.service';

@Component({
  selector: 'app-non-fraud-disputes',
  templateUrl: './non-fraud-disputes.component.html',
  styleUrls: ['./non-fraud-disputes.component.scss'],
})
export class NonFraudDisputesComponent implements OnInit {
  subscriptions: Subscription[] = [];
  columnHeader = this.configService.nonFraudColumnHeaders;
  filterArray = this.configService.defaultFilterArray;

  tableData: IDispute[] = [];
  activatedRow = null;

  constructor(
    private generalDataService: GeneralDataService,
    public dialog: MatDialog,
    private disputeService: DisputeService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    this.loadingService.startLoading();

    this.subscriptions.push(
      this.generalDataService.getOpenStatusDashboardView(true).subscribe({
        next: (data) => {
          this.tableData = data;
          this.tableData = data.filter(
            (x) => !this.configService.isFraud(x.reasonId)
          );
          this.disputeService.formatData(this.tableData);
        },
        error: (e) => this.alertService.addError('Unable to load data'),
        complete: () => this.loadingService.stopLoading(),
      })
    );
  }
  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  openDialog(data: any): void {
    this.activatedRow = data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.width = '500px';
    dialogConfig.position = { right: 'right' };
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100%';

    let dialogRef = this.dialog.open(
      DisputeDetailsDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.activatedRow = null;
      if (result) {
        this.loadData();
      }
    });
  }
}
