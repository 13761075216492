import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObservableService } from './observable.service';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  constructor(
    private http: HttpClient,
    private observableService: ObservableService
  ) {}

  get<T>(uri: string, options?: object): Observable<T> {
    return this.observableService.enableMulticast<T>(
      this.http.get<T>(uri, options)
    );
  }

  post<T>(uri: string, data: T, options?: object): Observable<T> {
    let json = JSON.stringify(data);
    return this.observableService.enableMulticast<T>(
      this.http.post<T>(uri, data, options)
    );
  }

  put<T>(uri: string, data: T, options?: object): Observable<T> {
    return this.observableService.enableMulticast<T>(
      this.http.put<T>(uri, data, options)
    );
  }

  delete<T>(uri: string, options?: object): Observable<T> {
    return this.observableService.enableMulticast<T>(
      this.http.delete<T>(uri, options)
    );
  }

  patch<T>(uri: string, data: any, options?: object): Observable<T> {
    return this.observableService.enableMulticast(
      this.http.patch<T>(uri, data, options)
    );
  }
}
