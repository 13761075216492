import { Component, Inject, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Subscription, combineLatest } from 'rxjs';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { CommonService } from 'src/app/services/common.service';
import { Employee } from 'src/app/shared/models/employee';
import { IEmployee } from 'src/app/shared/models/interfaces/i-employee';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  isUserLoggedIn!: boolean;
  subscriptions: Subscription[] = [];
  teller!: IEmployee;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private azureAdService: AzureAdService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.azureAdService.getIsUserLoggedIn().subscribe((x) => {
        this.isUserLoggedIn = x;
      })
    );

    if (this.isUserLoggedIn) {
      this.setTellerInfo();
    }
  }

  setTellerInfo() {
    let sub = combineLatest([
      this.azureAdService.getTellerId(),
      this.azureAdService.getAADdistgroup(),
    ]).subscribe(([teller, distGroups]) => {
      this.teller = teller;
      this.teller.isAdmin = this.commonService.getUserPrivilege(distGroups,'adminDG');
      this.azureAdService.setCurrentTeller(this.teller);
    });
    this.subscriptions.push(sub);
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.azureAdService.setCurrentTeller(new Employee());
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutUrl,
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
