<ng-container *ngIf="confirmationDialogData !== undefined">
    <div class="header">
        <h2 mat-dialog-title>{{confirmationDialogData.title}}</h2>
    </div>

    <div mat-dialog-content>
        <p>{{confirmationDialogData.message}}</p>

    <div *ngIf="confirmationDialogData.isRejected === false">
            <mat-form-field class="note-full-width" appearance="fill">
                <mat-label>Dispute Amount</mat-label>
                <input matInput type='text' appCurrencyInput [maxDigits]="10"
                [(ngModel)]="disputeAmount" name="disputeAmount" >
            </mat-form-field>     
            <mat-form-field class="note-full-width" appearance="fill">
                <mat-label>Reason</mat-label>
                <mat-select [(ngModel)]="reasonId" name="reasonId">
                    <mat-option *ngFor="let reason of reasons" [value]="reason.id">
                        {{reason.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="infoComment" class="note-full-width" appearance="fill">
                <mat-label>Additional Info</mat-label>
                <input matInput [(ngModel)]="infoComment" name="infoComment" #note maxlength="256">
                <mat-hint align="end">{{note.value.length}} / 256</mat-hint>
            </mat-form-field>       
    </div>

    <div *ngIf="confirmationDialogData.isRejected === true">
            <mat-form-field class="note-full-width" appearance="fill">
                <mat-label>Note</mat-label>
                <input matInput [(ngModel)]="resolutionReason" name="resolutionReason" #note maxlength="256" required>
                <mat-hint align="end">{{note.value.length}} / 256</mat-hint>
            </mat-form-field>
    </div>
</div>

    <div mat-dialog-actions [align]="'center'">
        <button mat-raised-button [mat-dialog-close]="false">
            Cancel
        </button>
        <ng-container *ngIf="confirmationDialogData.isRejected === true; else notRejection">
            <button mat-raised-button color="primary" [disabled]="!resolutionReason?.length" [mat-dialog-close]="[true,resolutionReason]">
                Submit 
            </button>            
        </ng-container>
        <ng-template #notRejection>
        <button mat-raised-button color="primary" [mat-dialog-close]="[true,resolutionReason,reasonId,infoComment,disputeAmount,appType]">
            Submit
        </button>            
        </ng-template>
    </div>
</ng-container>