import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { Employee } from '../shared/models/employee';
import { IEmployee } from '../shared/models/interfaces/i-employee';
import { ApiHelperService } from './api-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AzureAdService {
  private _isUserLoggedInFlag = new BehaviorSubject<boolean>(false);
  isUserLoggedInObs$ = this._isUserLoggedInFlag.asObservable();

  private _currentTeller = new BehaviorSubject<IEmployee>(new Employee());
  currentTellerObs$ = this._currentTeller.asObservable(); 

  constructor(private apiHelper: ApiHelperService) {}

  getIsUserLoggedIn(): Observable<boolean> {
    return this.isUserLoggedInObs$;
  }

  setIsUserLoggedIn(isUserLoggedIn: boolean) {
    this._isUserLoggedInFlag.next(isUserLoggedIn);
  }

  getCurrentTeller(): Observable<IEmployee> {
    return this.currentTellerObs$;
  }

  setCurrentTeller(teller: IEmployee) {
    this._currentTeller.next(teller);
  }

  getTellerId(): Observable<IEmployee> {
    return this.apiHelper.get<IEmployee>('https://graph.microsoft.com/beta/me');
  }

  getAADdistgroup(): Observable<any> {
    return of(localStorage.getItem('memberOf')).pipe(
      switchMap((groups) => {
        if (!groups) {
          return this.apiHelper
            .get<any>('https://graph.microsoft.com/v1.0/me/memberOf')
            .pipe(
              tap((ar) => localStorage.setItem('memberOf', JSON.stringify(ar)))
            );
        }
        return of(JSON.parse(groups));
      })
    );
    // return this.apiHelper.get<any>('https://graph.microsoft.com/v1.0/me/memberOf');
  }
}