import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalErrorHandlerService } from '@applefcu-dev/angular-utilities';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { CardDisputesComponent } from './card-disputes/card-disputes.component';
import { AllDisputesComponent } from './card-disputes/all-disputes/all-disputes.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { FraudDisputesComponent } from './card-disputes/fraud-disputes/fraud-disputes.component';
import { NonFraudDisputesComponent } from './card-disputes/non-fraud-disputes/non-fraud-disputes.component';
import { AppConfigService } from './services/app-config.service';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TopNavComponent } from './navigation/top-nav/top-nav.component';

export function initConfig(appConfigService: AppConfigService) {
  return () => appConfigService.loadSettings();
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({ declarations: [
        AppComponent,
        CardDisputesComponent,
        NonFraudDisputesComponent,
        AllDisputesComponent,
        FraudDisputesComponent,
        TopNavComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        SharedModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: 'https://login.microsoftonline.com/applefcu.org',
                redirectUri: environment.postLogoutUrl
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE, // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false
                }
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read']
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                ['https://graph.microsoft.com/beta/me', ['user.read']]
            ])
        })], providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            multi: true,
            deps: [AppConfigService],
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard,
        CurrencyPipe, DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
