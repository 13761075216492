import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { IConfirmationDialogData } from '../../models/interfaces/i-confirmation-dialog-data';
import { IReason } from '../../models/interfaces/i-reason';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  confirmationDialogData!: IConfirmationDialogData;
  resolutionReason: string | undefined;
  disputeAmount: number | undefined;
  reasonId: number | undefined;
  infoComment: string | undefined;
  reasons: IReason[] = [];
  subscription!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private generalDataService: GeneralDataService
  ) {}

  ngOnInit(): void {
    this.confirmationDialogData = this.data;
    this.reasonId = this.confirmationDialogData.reasonId;
    this.infoComment = this.confirmationDialogData.infoComment;
    this.disputeAmount = this.confirmationDialogData.disputeAmount;
    this.getReasons();
  }

  getReasons(): void {
    this.subscription = this.generalDataService
      .getReasons()
      .subscribe((data) => {
        let tempdata = data;
        let filteredReason = tempdata.find((x) => x.id === this.reasonId);
        this.reasons = tempdata.filter((x) => x.type === filteredReason?.type);
      });
  }

  get appType() {
    return this.reasons[0]?.type;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}