import { Injectable } from '@angular/core';
import { AppConfigurationClient } from '@azure/app-configuration';
import { environment } from 'src/environments/environment';
import { dynamicEnvironment } from 'src/envVars';
import { IConfigSettings } from '../shared/models/interfaces/i-config-settings';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configSettings!: IConfigSettings;

  constructor() {}

  async loadSettings() {
    const envLabel: string = (dynamicEnvironment as any).environment || (environment as any).environment || '';
    const conn = dynamicEnvironment.FrontendConfigConnectionString;
    const client = new AppConfigurationClient(conn);

    const apiUrl = await client
      .getConfigurationSetting({
        key: 'Api-Disputes',
        label: envLabel,
      })
      .finally();
    const apimKey = await client
      .getConfigurationSetting({
        key: 'Key-Disputes',
        label: envLabel,
      })
      .finally();

    this.configSettings = {
      baseUrl: apiUrl.value,
      apimKey: apimKey.value,
      label: envLabel,
    };

    return this.configSettings;
  }

  get config() {
    return this.configSettings;
  }

}
