import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private _activeErrors: string[] = [];

  errorsEmitter = new EventEmitter<string[]>(true);

  constructor() {}

  addError(alert: string, shouldLog: boolean = true) {
    let trimmedAlert = alert.trim();

    if (!this._activeErrors.includes(trimmedAlert)) {
      this._activeErrors.push(alert);
      this.errorsEmitter.emit(this._activeErrors);
    }

    if(shouldLog) console.error(alert);
  }

  clear() {
    this._activeErrors = [];
    this.errorsEmitter.emit([]);
  }
}
