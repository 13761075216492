import { IEmployee } from './interfaces/i-employee';

export class Employee implements IEmployee {
    id: string;
    displayName: string;
    jobTitle: string;
    mail: string;
    givenName: string;
    extension_00a97e3d347f48b7adc0e9018736f5f9_departmentNumber: string;
    extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID: number;
    isAdmin?:boolean;

    constructor() {
        this.id = '',
        this.displayName = '',
        this.jobTitle='',
        this.mail='',
        this.givenName='',
        this.extension_00a97e3d347f48b7adc0e9018736f5f9_departmentNumber='',
        this.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID=0
    }
}
