import { Injectable } from '@angular/core';
import { defer, interval, Observable, of, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObservableService {
  constructor() {}

  enableMulticast<T>(observable: Observable<T>): Observable<T> {
    const subject = new Subject<T>();
    observable.subscribe(subject);
    return subject.asObservable();
  }

  enableReplayMulticast<T>(observable: Observable<T>): Observable<T> {
    const subject = new ReplaySubject<T>();
    observable.subscribe(subject);
    return subject.asObservable();
  }

  // https://stackoverflow.com/a/48983205/4984553
  doOnSubscribe<T>(
    onSubscribe: () => void
  ): (source: Observable<T>) => Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
      return defer(() => {
        onSubscribe();
        return source;
      });
    };
  }

  // Takes a function that returns a boolean
  waitFor(conditionFn: any) {
    // If already true, no delay
    if (conditionFn()) {
      return of(true);
    } else {
      // Poll for condition to be true
      let subject = new Subject();
      let subscription = interval(10).subscribe(() => {
        if (conditionFn()) {
          subject.next(undefined);
          subject.complete();
          subscription.unsubscribe();
        }
      });

      return subject.asObservable();
    }
  }
}
