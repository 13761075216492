import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { Dispute } from '../../models/dispute';
import { IEmployee } from '../../models/interfaces/i-employee';
import { SubmitData } from '../../models/submit-data';
import { IReason } from '../../models/interfaces/i-reason';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() tableData: any;
  @Input() columnHeader: any;
  @Input() activatedRow: any;
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource();
  teller!: IEmployee;
  submitData = new SubmitData();
  subscriptions: Subscription[] = [];
  reasons: IReason[] = [];

  constructor(
    private generalDataService: GeneralDataService,
    private alertService: AlertService,
    private azureAdService: AzureAdService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.azureAdService.getCurrentTeller().subscribe((x) => (this.teller = x))
    );
    this.dataSource.data = this.tableData;
    this.subscriptions.push(
      this.generalDataService.getReasons().subscribe((data) => {
        this.reasons = data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData']) {
      this.dataSource.data = changes['tableData'].currentValue;
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item: any, property: any) => {
      switch (property) {
        case 'appleSubmitDate':
          return new Date(item.appleSubmitDate);
        case 'disputeAmount':
          return Number(item.disputeAmount.replace(/(?!-)[^0-9\.]+/g, ''));
        default:
          return item[property];
      }
    };
  }

  objectKeys(key: {}): string[] | undefined {
    if (key) {
      return Object.keys(key);
    }
    return;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onRowClick(row: any): void {
    this.activatedRow = row;
    this.rowAction.emit(row);
  }

  onChecked(row: any) {
    let _reason = this.reasons.find((x) => x.id === row.reasonId);
    this.submitData.isReviewed = row.isReviewed;
    this.submitData.lastModifiedTeller =
      this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
    this.changeViewStatus(row.disputeGuid, _reason!.type, this.submitData);
  }

  changeViewStatus(guid: string, type: number, data: SubmitData): void {
    this.subscriptions.push(
      this.generalDataService
        .updateDisputeReviewStatus(guid, type, data)
        .subscribe({
          error: (e) =>
            this.alertService.addError('Unable to update review status'),
          complete: () => {},
        })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
