import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class DisputeService {
  constructor(public currencyPipe:CurrencyPipe, public datePipe: DatePipe, private configService:ConfigService) {}

  formatData(data: any): void {
    data.forEach((a: any) => {
      if (a.pscuSubmitDate !== null) {
        a.disputeStatus = 'Sent to PSCU';
      }
      if (a.pscuSubmitDate === null && a.resolutionDate === null) {
        a.disputeStatus = 'Open';
      }
      if (a.resolutionDate !== null) {
        if (a.isResolvedInFavorOfMember == true) {
          a.disputeStatus = 'Accepted';
        } else {
          a.disputeStatus = 'Rejected';
        }
      }

      if (
        this.configService.isFraud(a.reasonId)
      ) {
        a.disputeType = 'Fraud';
      } else {
        a.disputeType = 'Non-Fraud';
      }

      a.disputeAmount = this.currencyPipe.transform(a.disputeAmount, 'USD', '');
      a.appleSubmitDate = this.datePipe.transform(a.appleSubmitDate, 'shortDate');
      a.modifiedDate = this.datePipe.transform(a.modifiedDate, 'shortDate');
      a.resolutionDate = this.datePipe.transform(a.resolutionDate, 'shortDate');
      a.transactionPostDate = this.datePipe.transform(a.transactionPostDate, 'shortDate');
    });
  }

}
