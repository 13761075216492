<div class="container" *ngIf="isUserLoggedIn" >
    <div class="header">
    <app-loading></app-loading>
    <app-alerts></app-alerts>
    <app-top-nav></app-top-nav>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <p>{{angularV}}</p>
    </div>
</div>