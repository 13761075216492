import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.loading.asObservable();

  constructor() { }

  startLoading() {
    this.loading.next(true);
  } 

  stopLoading() {
    this.loading.next(false);
  }
}
