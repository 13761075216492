<mat-form-field>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search" />
</mat-form-field>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
    <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader)">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columnHeader[tableData]}}
      </th>

      <td *matCellDef="let element" mat-cell>
        <ng-container *ngIf="tableData === 'isReviewed'; else modifyStatus">
          <mat-checkbox (click)="$event.stopPropagation()"
          [(ngModel)]="element[tableData]" 
          (change)='onChecked(element)'
          color='primary'>
          </mat-checkbox>
        </ng-container>

        <ng-template #modifyStatus>
          <ng-container *ngIf="tableData === 'disputeStatus'; else modifyDescription">
            <div class="status-class"
              [ngClass]="{class1: element.disputeStatus == 'Open', class2: element.disputeStatus == 'Accepted', class3: element.disputeStatus == 'Rejected', class4: element.disputeStatus == 'Sent to PSCU'}">
              {{element[tableData]}}
            </div>
          </ng-container>
        </ng-template>

        <ng-template #modifyDescription>
          <ng-container *ngIf="tableData === 'accountDescription'; else noChanges">
            {{element.isDebit === null ? '':(element.isDebit? 'DEBIT ' : 'CREDIT ')}}{{element[tableData]}}
          </ng-container>
        </ng-template>

        <ng-template #noChanges>{{element[tableData]}}</ng-template>
      </td>
    </ng-container>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data found</td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader)"></tr>
    <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader);" [class.active]="activatedRow === row"
      (click)="onRowClick(row)"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
</div>