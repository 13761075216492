import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  getUserPrivilege(data: any, group: string): boolean {
    let userGroup = group === 'adminDG' ? environment.adminDG : environment.usersSG;
    if (group === 'adminDG') {
      return data.value.some((obj: any) => userGroup.includes(obj.id));
    } else {
      return data.value.some((obj: any) => obj.id === userGroup);
    }
  }
}
