// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  postLogoutUrl: 'https://disputes-dashboard.stg.applefcu.org',
  clientId: '032f9e10-4b56-4171-831e-cdcdf74760a9',
  environment: 'Staging',
  // Software Engineering Distro, EServices Operations, Fraud, DCS
  adminDG:
    '1d91c1c1-51a6-416f-baa6-572bfe7d5779,ff2fff8c-5a41-49cb-a15f-5e6ababc0490,a4356019-fc57-4ac7-9ded-02ef5b5cc5af,a090683c-0377-4f00-8d0a-d7cc67de60d5,321c53ae-423c-4e5e-a12b-758c646ec24d,',
  //Disputes-Dashboard-Users
  usersSG: '155231d1-756b-4dc3-8059-b1b34a3a6835',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
