<ng-template #alertDialog let-alerts>
    <h2 mat-dialog-title>Error!</h2>
    <mat-dialog-content>
        <div *ngFor="let alert of alerts">
            {{ alert }}
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close color="primary">Dismiss</button>
    </mat-dialog-actions>
</ng-template>