import { Injectable } from '@angular/core';
import {
  GridOptions,
  ValueGetterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { ActionButtonComponent } from 'src/app/shared/components/action-button/action-button.component';

@Injectable({
  providedIn: 'root',
})
export class CardsGridService {
  constructor() {}

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 20,
    suppressCellFocus: true,
    columnDefs: [
      {
        headerName: '',
        field: 'isReviewed',
        cellEditor: 'agCheckboxCellEditor',
        editable: true,
        maxWidth: 80,
      },
      { field: 'memberNumber', filter: true },
      {
        headerName: 'Dispute Reason',
        valueGetter: (p: ValueGetterParams) =>
          p.data.reasonId + ' ' + p.data.reasonDescription,
      },
      { field: 'reasonId', hide: true, filter: true },
      { field: 'memberName' },
      { field: 'disputeAmount', valueFormatter: (p) => '$' + p.value },
      {
        headerName: 'Transaction Date',
        field: 'transactionPostDate',
        valueFormatter: this.dateFormatter,
        filter: 'agDateColumnFilter',
        filterParams: { comparator: this.filterParams },
      },
      { field: 'transactionAmount', valueFormatter: (p) => '$' + p.value },
      { field: 'transactionDescription', filter: true },
      { headerName: 'Card Number', field: 'accountDescription' },
      {
        headerName: 'Submit Date',
        field: 'appleSubmitDate',
        valueFormatter: this.dateFormatter,
        filter: 'agDateColumnFilter',
        filterParams: { comparator: this.filterParams },
      },
      { headerName: 'Submitted By', field: 'submittedByTeller' },
      {
        field: 'actions',
        headerName: 'Actions',
        cellRenderer: ActionButtonComponent,
        cellRendererParams: {
          onClick: this.onBtnClick.bind(this),
        },
      },
    ],
  };

  dateFormatter(params: ValueFormatterParams) {
    const date = new Date(params.value);
    return date.toLocaleDateString();
  }

  filterParams(filterLocalDateAtMidnight: Date, cellValue: string) {
    var cellDate = new Date(cellValue);
    cellDate.setHours(0, 0, 0, 0);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

  onBtnClick(params: any) {
    const rowNode = params.node;
    const updatedData = { ...rowNode.data, resolutionDate: new Date() };
    rowNode.setData(updatedData);
  }
}
