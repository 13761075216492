import { Component, OnInit } from '@angular/core';
import { AppMonitorService } from '@applefcu-dev/angular-utilities';

@Component({
  selector: 'app-card-disputes',
  templateUrl: './card-disputes.component.html',
  styleUrls: ['./card-disputes.component.scss'],
})
export class CardDisputesComponent implements OnInit {
  title = 'Disputes';

  navLinks = [
    {
      label: 'Needs response (Fraud)',
      link: './fraud',
      index: 1,
    },
    {
      label: 'All (Zelle) disputes',
      link: './all',
      index: 2,
    },
    {
      label: 'All (Card) disputes',
      link: './cards',
      index: 3,
    },
  ];

  constructor(private appMonitor: AppMonitorService) {
    this.appMonitor.init('DisputeDashboard');
  }

  ngOnInit(): void {}
}
