import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  GridApi,
  GridReadyEvent,
  GridOptions,
} from 'ag-grid-community'; 
import { map } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { CardsGridService } from './cards-grid.service';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';
import { IEmployee } from 'src/app/shared/models/interfaces/i-employee';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { SubmitData } from 'src/app/shared/models/submit-data';

@Component({
  selector: 'app-all-cards',
  standalone: true,
  imports: [AgGridAngular, AngularMaterialModule],
  templateUrl: './all-cards.component.html',
  styleUrl: './all-cards.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AllCardsComponent implements OnInit {
  gridApi!: GridApi;
  public rowData: any[] | undefined;
  gridOptions!: GridOptions;
  teller!: IEmployee;
  submitData = new SubmitData();

  constructor(
    private generalDataService: GeneralDataService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private cardsGridService: CardsGridService,
    private azureAdService: AzureAdService,
  ) {}

  ngOnInit(): void {
    this.gridOptions = this.cardsGridService.gridOptions;
    this.azureAdService.getCurrentTeller().subscribe((x) => {
      this.teller = x;
    });
    this.loadData();
  }

  loadData() {
    this.loadingService.startLoading();
    this.generalDataService
      .getOpenStatusDashboardView(false)
      .pipe(map((data) => data.filter((item) => item.isDebit)))
      .subscribe({
        next: (_data) => {
          this.rowData = _data;
        },
        error: (e) => this.alertService.addError('Unable to load data'),
        complete: () => this.loadingService.stopLoading(),
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    setInterval(()=>this.loadData(),300000);
    this.cdr.detectChanges(); // Trigger change detection
    params.api.sizeColumnsToFit(1700);
  }

  onCellValueChanged($event:any) {
    if ($event.type === 'cellValueChanged') {
      this.submitData.isReviewed = $event.newValue;
      this.submitData.lastModifiedTeller =
        this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
      this.generalDataService
        .updateDisputeReviewStatus(
          $event.data.disputeGuid,
          1,
          this.submitData
        )
        .subscribe({
          error: (e) =>
            this.alertService.addError('Unable to update review status'),
          complete: () => {},
        });
    }
  }

  applyFraudFilter() {
    this.gridApi.setFilterModel({
      reasonId: {
        filterType: 'number',
        operator: 'OR',
        conditions: [
          {
            filterType: 'number',
            type: 'equals',
            filter: 475,
          },
          {
            filterType: 'number',
            type: 'equals',
            filter: 481,
          },
        ],
      },
    });
  }

  applyNonFraudFilter() {
    this.gridApi.setFilterModel({
      reasonId: {
        filterType: 'number',
        operator: 'AND',
        conditions: [
          {
            filterType: 'number',
            type: 'notEqual',
            filter: 475,
          },
          {
            filterType: 'number',
            type: 'notEqual',
            filter: 481,
          },
        ],
      },
    });
  }

  resetFilter() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

}
