import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AllDisputesComponent } from './card-disputes/all-disputes/all-disputes.component';
import { CardDisputesComponent } from './card-disputes/card-disputes.component';
import { FraudDisputesComponent } from './card-disputes/fraud-disputes/fraud-disputes.component';
import { NonFraudDisputesComponent } from './card-disputes/non-fraud-disputes/non-fraud-disputes.component';
import { HomeComponent } from './shared/components/home/home.component';
import { AuthGuard } from './services/auth.guard';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ViewGuard } from './services/view.guard';
import { AllCardsComponent } from './card-disputes/all-cards/all-cards.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'dashboard',
    component: CardDisputesComponent,
    canActivate: [AuthGuard, MsalGuard],
    canActivateChild: [ViewGuard, AuthGuard, MsalGuard],
    children: [
      {
        path: 'non-fraud',
        component: NonFraudDisputesComponent,
      },
      {
        path: 'fraud',
        component: FraudDisputesComponent,
      },
      {
        path: 'all',
        component: AllDisputesComponent,
      },
      {
        path: 'cards',
        component: AllCardsComponent,
      }
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
