<ng-container *ngIf="disputeDetail !== undefined">
    <h2 mat-dialog-title>Dispute Details</h2>
    <mat-dialog-content>
        <mat-list>
            <mat-list-item>
                <span matListItemTitle>Description</span>
                <span matListItemLine>{{disputeDetail.transactionDescription}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="!disputeDetail.transactionDate">
                <span matListItemTitle>Transaction Date</span>
                <span matListItemLine>{{disputeDetail.transactionDate | date: 'shortDate'}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Posting Date</span>
                <span matListItemLine>{{disputeDetail.transactionPostDate | date: 'shortDate'}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Transaction Amount</span>
                <span matListItemLine>{{disputeDetail.transactionAmount | currency}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Disputed Amount</span>
                <span matListItemLine>{{disputeDetail.disputeAmount | currency}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Status</span>
                <span matListItemLine>{{disputeDetail.disputeStatus }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Reason</span>
                <span matListItemLine>{{disputeDetail.reasonDescription }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>            
            <mat-list-item class='calcHeight' *ngIf="disputeDetail.infoComment">
                <span matListItemTitle>Additional Comments</span>
                <span matListItemLine class="manual-break">{{disputeDetail.infoComment}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="disputeDetail.intendedRecipientName">
                <span matListItemTitle>Recipient Name</span>
                <span matListItemLine>{{disputeDetail.intendedRecipientName}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>   
            <mat-list-item *ngIf="disputeDetail.intendedRecipientContact">
                <span matListItemTitle>Recipient Contact</span>
                <span matListItemLine>{{disputeDetail.intendedRecipientContact}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>     
            <mat-list-item *ngIf="disputeDetail.scamType">
                <span matListItemTitle>Scam Type</span>
                <span matListItemLine>{{disputeDetail.scamType}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.contactMethodOfImposter">
                <span matListItemTitle>Contact Method Of Imposter</span>
                <span matListItemLine>{{disputeDetail.contactMethodOfImposter}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.impersonatingEntityType">
                <span matListItemTitle>Impersonating Entity Type</span>
                <span matListItemLine>{{disputeDetail.impersonatingEntityType}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>                                        
            <mat-list-item>
                <span matListItemTitle>Dispute Type</span>
                <span matListItemLine>{{disputeDetail.disputeType }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Member Name</span>
                <span matListItemLine>{{disputeDetail.memberName }}</span>
           </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Member Number</span>
                <span matListItemLine>{{disputeDetail.memberNumber }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Source Type</span>
                <span matListItemLine>{{disputeDetail.accountDescription }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <span matListItemTitle>Dispute Date</span>
                <span matListItemLine>{{disputeDetail.appleSubmitDate | date: 'shortDate'}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.submittedByTeller">
                <span matListItemTitle>Submitted by</span>
                <span matListItemLine>Teller# {{disputeDetail.submittedByTeller}} on {{disputeDetail.appleSubmitDate | date: 'shortDate'}}</span>
            </mat-list-item>           
            <mat-divider></mat-divider>   
            <mat-list-item *ngIf="disputeDetail.lastModifiedTeller">
                <span matListItemTitle>Last Modified by</span>
                <span matListItemLine>Teller# {{disputeDetail.lastModifiedTeller}} on {{disputeDetail.modifiedDate | date: 'shortDate'}}</span>
            </mat-list-item>                      
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="disputeDetail.resolvedByTeller">
                <span matListItemTitle>Processed by</span>
                <span matListItemLine>Teller# {{disputeDetail.resolvedByTeller}} on {{disputeDetail.resolutionDate | date: 'shortDate'}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>        
            <mat-list-item class='calcHeight' *ngIf="disputeDetail.resolutionReason">
                <span matListItemTitle>Reject Reason</span>
                <span matListItemLine class='manual-break'>{{disputeDetail.resolutionReason}}</span>
            </mat-list-item>
            <mat-divider></mat-divider>           
        </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close>Close</button>
            <ng-container *ngIf="disputeDetail.disputeStatus == 'Open' && isAdmin">
                <button mat-raised-button color="warn" (click)="onClickReject()"[disabled]='btnstate'>Reject</button>
                <button mat-raised-button color="success" (click)="onClickApprove()"[disabled]='btnstate'>Approve</button>
                <button mat-raised-button color="primary" (click)="onClickEdit()" [disabled]='btnstate'>Edit</button>
            </ng-container>
            <ng-container *ngIf="disputeDetail.resolutionDate !== null && isAdmin">
                <button mat-raised-button color="warn" (click)="onClickReopen()"[disabled]='btnstate'>Reopen</button>
            </ng-container>
    </mat-dialog-actions>
</ng-container>