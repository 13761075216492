import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AzureAdService } from 'src/app/services/azure-ad.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GeneralDataService } from 'src/app/services/general-data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Dispute } from '../../models/dispute';
import { SubmitData } from '../../models/submit-data';
import { IEmployee } from '../../models/interfaces/i-employee';

@Component({
  selector: 'app-dispute-details-dialog',
  templateUrl: './dispute-details-dialog.component.html',
  styleUrls: ['./dispute-details-dialog.component.scss'],
})
export class DisputeDetailsDialogComponent implements OnInit {
  subscriptions: Subscription[] = [];
  disputeDetail!: Dispute;
  submitData = new SubmitData();
  btnstate: boolean = false;
  teller!: IEmployee;

  constructor(
    private generalDataService: GeneralDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DisputeDetailsDialogComponent>,
    private dialogService: DialogService,
    private azureAdService: AzureAdService,
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.disputeDetail = this.data;
    this.azureAdService.getCurrentTeller().subscribe((x) => {
      this.teller = x;
    });
  }

  get isAdmin() {
    return this.teller.isAdmin;
  }

  onClickReject(): void {
    this.dialogService
      .confirmationDialog({
        title: 'Are you sure you want to reject?',
        message: 'An explanation is required.',
        isRejected: true,
      })
      .subscribe((result) => {
        if (result[0]) {
          this.inProcess();
          this.submitData.tellerNumber = this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
          this.submitData.resolutionReason = result[1];
          this.subscriptions.push(
            this.generalDataService
              .rejectDispute(this.disputeDetail.disputeGuid, this.submitData)
              .subscribe({
                error: (e) => {
                  console.log(e), this.formatError(e, 'Reject');
                },
                complete: () => this.dialogRef.close(this.disputeDetail),
              })
          );
        } else {
          this.dialogRef.close();
        }
      });
  }

  onClickApprove(): void {
    this.dialogService
      .confirmationDialog({
        title: 'Are you sure you want to approve?',
        message:
          'Accepting will refund the disputed amount, but does not consider additional fees that may have been incurred by the member as a result of the transaction.',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.inProcess();
          this.submitData.tellerNumber = this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
          this.subscriptions.push(
            this.generalDataService
              .payDispute(this.disputeDetail.disputeGuid, this.submitData)
              .subscribe({
                error: (e) => {
                  console.log(e), this.formatError(e, 'Approve');
                },
                complete: () => this.dialogRef.close(this.disputeDetail),
              })
          );
        } else {
          this.dialogRef.close();
        }
      });
  }

  onClickEdit(): void {
    this.dialogService
      .confirmationDialog({
        title: 'Are you sure you want to edit?',
        message: 'Edit the field(s) below.',
        disputeAmount: this.disputeDetail.disputeAmount,
        reasonId: this.disputeDetail.reasonId,
        infoComment: this.disputeDetail.infoComment,
        isRejected: false,
      })
      .subscribe((result) => {
        if (result[0]) {
          this.inProcess();
          this.submitData.lastModifiedTeller = this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
          this.submitData.isReviewed = true;
          if (result[2]) {
            this.submitData.reasonId = result[2];
          }
          if (result[3]) {
            this.submitData.infoComment = result[3];
          }
          if (result[4]) {
            this.submitData.disputeAmount = result[4];
          }

          let obs =
            result[5] === 1
              ? this.generalDataService.updatePscuDispute(
                  this.disputeDetail.disputeGuid,
                  this.submitData
                )
              : this.generalDataService.updateZelleDispute(
                  this.disputeDetail.disputeGuid,
                  this.submitData
                );

          this.subscriptions.push(
            obs.subscribe({
              error: (e) => {
                console.log(e), this.formatError(e, 'Edit');
              },
              complete: () => this.dialogRef.close(this.disputeDetail),
            })
          );
        } else {
          this.dialogRef.close();
        }
      });
  }

  onClickReopen(): void {
    this.dialogService
      .confirmationDialog({
        title: 'Are you sure you want to re-open?',
        message: 'This will change the dispute status to Open.',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.inProcess();
          this.submitData.lastModifiedTeller = this.teller.extension_00a97e3d347f48b7adc0e9018736f5f9_tellerID;
          this.subscriptions.push(
            this.generalDataService
              .reopenDispute(this.disputeDetail.disputeGuid, this.submitData)
              .subscribe({
                error: (e) => {
                  console.log(e), this.formatError(e, 'Reopen');
                },
                complete: () => this.dialogRef.close(this.disputeDetail),
              })
          );
        } else {
          this.dialogRef.close();
        }
      });
  }

  inProcess(): void {
    this.loadingService.startLoading();
    this.btnstate = true;
  }

  formatError(e: any, msg: string): void {
    return typeof e.error === 'string'
      ? this.alertService.addError(`Unable to ${msg} - ` + e.error)
      : this.alertService.addError(`Unable to ${msg}`);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
