import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  subscriptions: Subscription[] = [];
  alerts: string[] = [];

  constructor(
    private alertService: AlertService,
    public errorDialog: MatDialog,
    private loadingService: LoadingService
  ) {}

  @ViewChild('alertDialog', { static: true })
  alertDialog!: TemplateRef<any>;

  ngOnInit(): void {
    this.subscriptions.push(
      this.alertService.errorsEmitter.subscribe((data) => {
        this.alerts = data;
        if (this.alerts.length > 0) {
          this.openDialogWithoutRef();
        }
      })
    );
  }

  openDialogWithoutRef() {
    const tempDialog = this.errorDialog.open(this.alertDialog, {
      data: this.alerts,
      disableClose: true,
    });
    this.loadingService.stopLoading();

    tempDialog.afterClosed().subscribe((res) => {
      this.alertService.clear();
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
