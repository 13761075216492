<ng-container>
    <div class='custom-toggle-group'>
        <mat-button-toggle-group #toggleBtn='matButtonToggleGroup' value="reset">
            <mat-button-toggle value="fraud" (click)="applyFraudFilter()">Fraud</mat-button-toggle>
            <mat-button-toggle value="nonFraud" (click)="applyNonFraudFilter()">Non-Fraud</mat-button-toggle>
            <mat-button-toggle value="reset" (click)="resetFilter()">All</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="custom-grid mat-elevation-z8">
        <ag-grid-angular 
            style="width: 100%; height: 500px;" 
            class="ag-theme-material" 
            [rowData]="rowData"
            [gridOptions]="gridOptions" 
            (cellValueChanged)="onCellValueChanged($event)"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</ng-container>