import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DisputeDetailsDialogComponent } from './components/dispute-details-dialog/dispute-details-dialog.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CurrencyInputDirective } from './components/directives/currency-input.directive';
import { AlertsComponent } from './components/alerts/alerts.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    CustomTableComponent,
    ConfirmationDialogComponent,
    DisputeDetailsDialogComponent,
    HomeComponent,
    LoadingComponent,
    CurrencyInputDirective,
    AlertsComponent,
    UnauthorizedComponent,
  ],
  imports: [CommonModule, AngularMaterialModule, FormsModule],
  exports: [CommonModule, CustomTableComponent,DisputeDetailsDialogComponent, LoadingComponent, AlertsComponent],
})
export class SharedModule {}
